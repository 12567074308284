<template>
  <img
    class="titleScreen img-fluid"
    src="../assets/img/title-parlons-fourrage@2x.jpg"
    alt=""
  />
  <div class="row">
    <div class="col-md d-flex align-items-center">
      <img class="img-fluid" src="../assets/img/visu-home@2x.png" alt="" />
    </div>
    <div class="col-md d-flex align-items-center">
      <div class="characterChoice">
        <p>
          Joris et Flora sont exploitants agricoles. <br />Sont-ils assez
          prudents pendant leurs foins afin d'éviter les risques liés à
          l'échauffement des fourrages ? <br />
          Prenez 5 à 10 minutes pour leur faire profiter de votre expérience !
        </p>
        <div class="d-flex flex-column d-sm-block d-md-flex d-lg-block  mb-5">
          <button
            @click="JorisPicked()"
            class="btn btn-primary mb-4 mb-sm-0 mb-md-4 mb-lg-0"
          >
            Je choisis Joris
          </button>
          <button
            @click="FloraPicked()"
            class="btn btn-secondary ml-sm-4 ml-md-0 ml-lg-4"
          >
            Je choisis Flora
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//Importe les deux différents json pour chaque personnage
import quizJoris from "@/assets/quizListJoris.json";
import quizFlora from "@/assets/quizListFlora.json";
import TC_Wrapper from "vue-tag-commander";
export default {
  name: "Home",
  data() {
    return {
      quizPicked: [],
      quizForJoris: quizJoris,
      quizForFlora: quizFlora,
    };
  },
  mounted() {
    this.$emit("home-loaded");
    // Récupérer l'instance de TC_Wrapper
    const wrapper = TC_Wrapper.getInstance();

    // Mettre à jour les variables souhaitées
    wrapper.setTcVars({
      environnement_technique_du_site: window.location.href.includes(
        "www.parlons-fourrage.credit-agricole.fr"
      )
        ? "prod" // Set to "prod" if the URL contains "www.parlons-fourrage.credit-agricole.fr"
        : "preprod", // Otherwise, set to "preprod"
      environnement_nom_du_site: document.title,
      page: "Accueil",
      environnement_distributeur: "",
      environnement_id_CR: "",
      page_url: window.location.href,
    });
    document.getElementById('pageVirtuelle').click();
    //console.log('page accueil');

    // tC.event.pageVirtuelle();
  },
  methods: {
    //Le bouton choisir déclenche une méthode qui récupère le bon quiz et l'envoie dans le store avec le bon personnage pour charger le bon quiz
    JorisPicked() {
      this.quizPicked = this.quizForJoris;
      this.$store.commit("updatingCharacters", "Joris");
      this.$store.commit("updatingQuiz", this.quizPicked);
      this.$router.push("Quiz");
    },
    FloraPicked() {
      this.quizPicked = this.quizForFlora;
      this.$store.commit("updatingCharacters", "Flora");
      this.$store.commit("updatingQuiz", this.quizPicked);
      this.$router.push("Quiz");
    },
  },
};
</script>

<style scoped>
.titleScreen {
  width: 486px;
}

.btn-primary {
  background: #006a4e;
}
.btn-secondary {
  background: #1b9787;
}
p {
  color: #006a4e;
  font-weight: bold;
  font-size: 2rem;
}
</style>
